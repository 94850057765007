var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[_c('div',{ref:"box"},[_c('div',{ref:"header"},[_c('a-page-header',{staticStyle:{"border-bottom":"1px solid rgb(235, 237, 240)"},attrs:{"title":"发放佣金"}})],1),_c('a-form',{staticStyle:{"margin-top":"24px"},attrs:{"form":_vm.form,"label-col":_vm.labelCol,"wrapper-col":_vm.wrapperCol},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',{attrs:{"label":"用户"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'user',
          {
            rules: [{ required: true, message: '请选择用户!' }],
          } ]),expression:"[\n          'user',\n          {\n            rules: [{ required: true, message: '请选择用户!' }],\n          },\n        ]"}],attrs:{"placeholder":"点击选择用户"},on:{"click":_vm.showUser}})],1),_c('a-form-item',{attrs:{"label":"类型"}},[_c('a-radio-group',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'pm',
          {
            rules: [{ required: true, message: '请选择类型!' }],
          } ]),expression:"[\n          'pm',\n          {\n            rules: [{ required: true, message: '请选择类型!' }],\n          },\n        ]"}]},[_c('a-radio',{attrs:{"value":1}},[_vm._v(" 增加 ")]),_c('a-radio',{attrs:{"value":0}},[_vm._v(" 减少 ")])],1)],1),_c('a-form-item',{attrs:{"label":"金额(元)："}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'amount',
          {
            rules: [{ required: true, message: '请输入金额!' }],
          } ]),expression:"[\n          'amount',\n          {\n            rules: [{ required: true, message: '请输入金额!' }],\n          },\n        ]"}],attrs:{"min":0,"precision":2,"step":"0.01","placeholder":"请输入金额"}})],1),_c('a-form-item',{attrs:{"label":"带货总数："}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'order_num_total',
          {
            rules: [{ required: true, message: '请输入带货总数!' }],
          } ]),expression:"[\n          'order_num_total',\n          {\n            rules: [{ required: true, message: '请输入带货总数!' }],\n          },\n        ]"}],attrs:{"min":1,"precision":0,"step":"1","placeholder":"请输入带货总数"}})],1),_c('a-form-item',{attrs:{"wrapper-col":{ span: 14, offset: 4 }}},[_c('a-button',{attrs:{"type":"primary","html-type":"submit"}},[_vm._v(" 发放 ")])],1)],1)],1),_c('a-modal',{attrs:{"width":"700px","title":"请选择用户"},model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}},[_c('div',[_c('div',{ref:"form"},[_c('a-form',{ref:"form",staticStyle:{"padding":"15px 0"},attrs:{"form":_vm.userform,"layout":"inline"},on:{"submit":_vm.getSearch}},[_c('a-form-item',{attrs:{"label":""}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'nickname',
              { rules: [{ required: false }] } ]),expression:"[\n              'nickname',\n              { rules: [{ required: false }] },\n            ]"}],attrs:{"placeholder":"用户昵称"}})],1),_c('a-form-item',{attrs:{"label":""}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'mobile',
              { rules: [{ required: false }] } ]),expression:"[\n              'mobile',\n              { rules: [{ required: false }] },\n            ]"}],attrs:{"placeholder":"用户手机号"}})],1),_c('a-form-item',[_c('a-button',{attrs:{"type":"primary","html-type":"submit"}},[_vm._v(" 搜索 ")])],1)],1)],1),_c('a-table',{ref:"tableList",attrs:{"columns":_vm.columns,"data-source":_vm.userList,"pagination":false,"row-key":function (record) { return record.id; },"size":"middle"},scopedSlots:_vm._u([{key:"btns",fn:function(btns, num){return _c('span',{},[_c('a-button',{staticClass:"editable-add-btn",staticStyle:{"margin":"5px"},attrs:{"size":"small"},on:{"click":function($event){return _vm.handel(num)}}},[_vm._v(" 选择 ")])],1)}}])}),_c('div',{ref:"pagination"},[(_vm.pageshow)?_c('a-pagination',{attrs:{"default-current":_vm.currentPage,"defaultPageSize":_vm.pageSize,"total":_vm.total_num,"show-total":function (total) { return ("共 " + total + " 条"); }},scopedSlots:_vm._u([{key:"buildOptionText",fn:function(props){return [_c('span',[_vm._v(_vm._s(props.value)+"条/页")])]}}],null,false,198454938)}):_vm._e()],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }
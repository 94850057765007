<template>
  <div class="container">
    <div ref="box">
      <div ref="header">
        <a-page-header style="border-bottom: 1px solid rgb(235, 237, 240)" title="发放佣金">
        </a-page-header>
      </div>

      <a-form :form="form" :label-col="labelCol" :wrapper-col="wrapperCol" @submit="handleSubmit"
        style="margin-top: 24px">
        <a-form-item label="用户">
          <a-input v-decorator="[
            'user',
            {
              rules: [{ required: true, message: '请选择用户!' }],
            },
          ]" @click='showUser' placeholder='点击选择用户' />
        </a-form-item>
        <a-form-item label="类型">
          <a-radio-group v-decorator="[
            'pm',
            {
              rules: [{ required: true, message: '请选择类型!' }],
            },
          ]">
            <a-radio :value="1"> 增加 </a-radio>
            <a-radio :value="0"> 减少 </a-radio>
          </a-radio-group>
        </a-form-item>
        <a-form-item label="金额(元)：">
          <a-input-number :min="0" :precision="2" step=0.01 v-decorator="[
            'amount',
            {
              rules: [{ required: true, message: '请输入金额!' }],
            },
          ]" placeholder='请输入金额' />
        </a-form-item>
        <a-form-item label="带货总数：">
          <a-input-number :min="1" :precision="0" step=1 v-decorator="[
            'order_num_total',
            {
              rules: [{ required: true, message: '请输入带货总数!' }],
            },
          ]" placeholder='请输入带货总数' />
        </a-form-item>

        <a-form-item :wrapper-col="{ span: 14, offset: 4 }">
          <a-button type="primary" html-type="submit"> 发放 </a-button>
        </a-form-item>
      </a-form>
    </div>
    <a-modal width="700px" v-model="visible" title="请选择用户">
      <div>
        <div ref="form">
          <a-form :form="userform" layout="inline" @submit="getSearch" style="padding: 15px 0" ref="form">
            <a-form-item label="">
              <a-input v-decorator="[
                'nickname',
                { rules: [{ required: false }] },
              ]" placeholder='用户昵称' />
            </a-form-item>
            <a-form-item label="">
              <a-input v-decorator="[
                'mobile',
                { rules: [{ required: false }] },
              ]" placeholder='用户手机号' />
            </a-form-item>
            <a-form-item>
              <a-button type="primary" html-type="submit">
                搜索
              </a-button>
            </a-form-item>
          </a-form>
        </div>
        <a-table :columns="columns" :data-source="userList" :pagination="false" :row-key="(record) => record.id"
          size="middle" ref="tableList">
          <span slot="btns" slot-scope="btns, num">
            <a-button class="editable-add-btn" @click="handel(num)" size="small" style="margin: 5px">
              选择
            </a-button>
          </span>
        </a-table>

        <div ref="pagination">
          <a-pagination v-if="pageshow" :default-current="currentPage" :defaultPageSize="pageSize" :total="total_num"
            :show-total="(total) => `共 ${total} 条`">
            <template slot="buildOptionText" slot-scope="props">
              <span>{{ props.value }}条/页</span>
            </template>
          </a-pagination>
        </div>
      </div>
    </a-modal>
  </div>
</template>
<script>
export default {
  name: "Car",
  data() {
    return {
      form: this.$form.createForm(this, { name: "deviceSearch" }),
      userform: this.$form.createForm(this, { name: "userform" }),
      labelCol: { span: 4 },
      wrapperCol: { span: 16 },
      visible: false,
      pageshow: false,
      total_num: 0,
      currentPage: 1,
      userList: [],
      pageSize: 10,
      user_id: '',
      columns: [
        {
          title: "用户昵称",
          key: "nickname",
          dataIndex: "nickname",
          align: "center",
        },
        {
          title: "用户手机号",
          key: "mobile",
          dataIndex: "mobile",
          align: "center",
        },
        {
          title: "操作",
          key: "btns",
          dataIndex: "btns",
          scopedSlots: { customRender: "btns" },
          align: "center",
          width: 130,
          fixed: "right",
        },
      ],
    };
  },
  methods: {
    showUser() {
      this.visible = true
      this.getSearchList({})
    },
    handleSubmit(e) {
      e.preventDefault();
      var that = this;
      that.form.validateFields((error, values) => {
        if (!error) {
          var data = values;
          data.user_id = that.user_id
          data.activity_id = 42
          delete data.user
          this.$confirm({
            title: '提示',
            content: '是否确认发放佣金？',
            okText:'确认',
            cancelText:'取消',
            onOk() {

              that.axios.post("/admin/DouyinJingdongNew20221011/reward", data).then((res) => {
                if (that.$code(res)) {
                  that.$message.success("发放成功");
                  that.form.resetFields()
                }
              });
            },
            onCancel() { },
          });
        }
      });
    },
    getSearchList(data) {
      var that = this;
      data.limit = that.pageSize;
      data.page = that.currentPage;
      that.$message.loading({ content: "加载中..", key: "load", duration: 0 });
      that.pageshow = false;
      that.axios
        .get("/admin/homeUsers/list", {
          params: data,
        })
        .then((res) => {
          that.$message.loading({
            content: "加载中..",
            key: "load",
            duration: 0.00001,
          });
          if (that.$code(res)) {
            var userList = res.data.data;
            that.userList = userList;
            that.total_num = res.data.count;
            that.currentPage = res.data.currentPage;
          }
        });

      that.$nextTick(() => {
        that.pageshow = true;
      });
    },
    getSearch(e) {
      if (e) {
        e.preventDefault();
      }
      var that = this;
      that.userform.validateFields((error, values) => {
        that.getSearchList(values);
      });
    },
    handel(mes) {
      this.form.setFieldsValue({
        user: mes.nickname + " / " + mes.mobile
      })
      this.visible = false;
      this.currentPage = 1
      this.user_id = mes.id
      this.userList= []
      this.userform.resetFields()
    }
  },
};
</script>
<style scoped>
table img {
  width: 60px;
  height: 60px;
  border-radius: 30px;
}

table .screenshot {
  width: 60px;
  height: auto;
  border-radius: 0;
}

.ant-table-wrapper {
  height: auto;
}

/deep/.ant-modal-body {
  padding-top: 0
}

/* 
.ant-modal-body {
  display: flex;
  justify-content: center;
  align-items: center;
}

.ant-modal-body div {
  text-align: center;
  margin: 0 20px;
}

.ant-modal-body img {
  width: 150px;
}

.ant-modal-body div {
  width: 100%;
  text-align: left;
  margin: 0;
} */
/deep/.ant-modal-footer {
  display: none;
}

.section.ant-layout .container .ant-table-wrapper {
  overflow: scroll;
}

.section.ant-layout .container .ant-table-wrapper::-webkit-scrollbar {
  width: 0;
}

.ant-form-item-children {
  width: 100%;
  display: block;
}

.ant-input-number {
  width: 100%
}
</style>